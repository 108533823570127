export interface KeyboardsInterface {
  [index: string]: {
    name: string,
    imageFile: string,
    description: string,
  };
}


export const keyboards: KeyboardsInterface = { 
  "aboutus": {
    name: "CannonKeysとは",
    imageFile: "bg-aboutus.jpg",
    description: `CannonKeysはアメリカのロードアイランド州に拠点を置くキーボード会社です。  
より良いキーボードを全世界に届けることを目標に2019年に創立され、現在では4カ国に渡る国際的な企業にまで拡大しました。  
<br/>
自社のデザインスキルと社員全員のキーボードに対する愛を最大限まで活かすことで、他には真似できないキーボード作りを行っています。  
<br/>
自社設計のキーボードやキーセットの販売以外にも、コミュニティで活動する設計者をサポートしてデザインの生産・販売を担当したり、キーボードを組み上げるのに必要なパーツや道具を幅広く揃えることによって、より多くの人にカスタムキーボードを楽しんでもらうために努力しています。  
`
  },
  "aboutkeyboards": {
    name: "カスタムキーボードとは",
    imageFile: "bg-aboutkeyboards.jpg",
    description: `海外のソーシャルメディアでは参加者が100万人を超える、超巨大規模のホビーです。  
<br/>
自分好みのキーボードキットを選び、パーツを選び組み合わせて、念入りなチューニングを施すことによって、自分にとって最高峰のキーボードを組み上げることを目標とする人が多いです。  
キーの打ち心地や打鍵音の厳選、見た目のカスタマイズ、性能や機能の両立など、自分好みの世界に一つだけのキーボードを作ることができます。  
<br/>
高級・限定キーボードの収集など、腕時計などの趣味に似た一面を持ちます。`
  },
  "brutal-v2": {
    name: "Brutal V2",
    imageFile: "cover-brutalv2.jpg",
    description: `
CannonKeysのBrutalistシリーズの2世代目として設計されたキーボードです。  
<br/>
ブルータリズム建築の特徴的な大胆さと力強さを、カスタムキーボードに取り入れて表現したデザインです。  
重量とサイズ感、シャープなエッジとを、高級キーボードに必要とされる洗練さに融合させることで、絶妙なバランスに仕上げました。  
<br/>
内部構造はプレートの下面に衝撃吸収材を搭載する片面ガスケットマウント構造を採用し、あえて一部の振動をケースに伝え、ケースの形状で増幅させることによって、名前の通り直接的な打鍵音を作り出しています。  
素材の表面を残した鋳造ウエイトを内部に搭載し、打鍵音を発揮するために制振機能を追加するとともに、内部までブルータリズム建築の意思を尊重しました。  
<br/>
<small>現在発売中   
展示品のパーツ：  
・プレート：アルミ  
・スイッチ：Gateron Oil Kings  
・キーセット：NicePBT Vasilisa</small>`
  },
  "altair-x": {
    name: "Altair-X",
    imageFile: "cover-altairx.jpg",
    description: `ai03によって設計された3世代目のエルゴノミックキーボードです。  
キー配列は、以前のデザインから１年以上をかけて実用性を検証して、より使いやすく、手への負荷を減らすキー配置に改良しました。  
<br/>
一つのアルミブロックから削り出されたケースは、キー配置と融合する曲線を基礎にしたデザインを作り上げました。  
下面のステンレス製のウエイトは、見た目のアクセントであると同時に、使用時にキーボードを安定させる重りの機能を持ちます。  
<br/>
内部構造は小型のフォームブロックによって下から支えられ、使用時に衝撃を逃がすために全方向に傾きます。  
<br/>
<small>
2023年販売予定  
展示品のパーツ：  
・プレート：ポリカ  
・スイッチ：Mekanisk Ultramarine  
・キーセット：EnjoyPBT ModernJA  </small>`
  },
  "vector": {
    name: "Vector",
    imageFile: "cover-vector.jpg",
    description: `レトロとモダンを融合させたキーボードです。  
<br/>
あえてデザインの一部として利用した継ぎ目、傾斜付きのベゼル、ペンを置くレールなどの昔のキーボードで採用されていた特徴を、現代的で洗練された外観に取り込みました。  
時代を問わない美を目標としたデザインの下面には、周りに溶け込む巨大なウエイトを搭載し、使用時の安定感を増やすと共にレーザー彫刻の模様を施すキャンバスを作り出しています。  
<br/>
内部構造はCherry G80シリーズなどの初期のキーボードに対してオマージュを込めて、プレート無し・基盤のみのPCBマウント構造を選びました。  
<br/>
<small>
2022年販売  
展示品のパーツ：  
・スイッチ：SP-Star Sacramento  
・キーセット：NicePBT Classic Beige 日本語版  </small>`
  },
  "andromeda": {
    name: "Andromeda",
    imageFile: "cover-andromeda.jpg",
    description: `キーボードの最高峰の性能と美を追求して設計されたフラグシップモデルです。  
しっかりとした底打ちと長時間の使用でも疲れない柔らかさ、落ち着いた打鍵音と心地よいかつ複雑な音色など、使用されるパーツの最大の性能を引き出す構造を搭載しています。  
<br/>
外観は布のような柔らかさから幾何学的な張りに変化する、キーボードでは類を見ない彫刻のような表現に挑戦しました。  
<br/>
今回の展示品は、厳選されたヴィンテージパーツとチューニングで仕上げました。  
<br/>
<small>
2022年販売  
展示品のパーツ：  
・プレート：アルミ  
・スイッチ：Cherry MX 黒軸（1985年製、西ドイツ産）    
・キーセット：Cherry製二重成形キーキャップ（1990年代製）  </small>`
  },
  "ripple": {
    name: "Ripple",
    imageFile: "cover-ripple.jpg",
    description: `水の動きから発想を得て設計されたキーボードです。  
ケース側面の水の流れの表現や水面の波紋を捉えたウエイトなど、硬いアルミや真鍮の素材で柔らかさや動きを作り出しています。  
<br/>
内部構造も水の流れのように柔軟で、複数のガスケット素材と小型Oリングを組み合わせることによって、打鍵感と打鍵音を使用者に合わせて自由自在に変化させることができます。  
<br/>
<small>
2023年販売  
展示品のパーツ：  
・プレート：FR4  
・スイッチ：Mechs on Deck Anubis  
・キーセット：NicePBT Winter Tundra  </small>`
  }


}