import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { HomeScreen } from './HomeScreen';
import { DetailsPane } from './DetailsPane';


import './fonts/LineSeed/LINESeedJP_OTF_Bd.woff2';
import './fonts/LineSeed/LINESeedJP_OTF_Eb.woff2';
import './fonts/LineSeed/LINESeedJP_OTF_Rg.woff2';
import './fonts/LineSeed/LINESeedJP_OTF_Th.woff2';


function App() {

  const [currentDetailsPane, setCurrentDetailsPane] = useState<string>("")

  return (
<>
      {currentDetailsPane === "" ?
      <HomeScreen setCurrentDetailsPane={setCurrentDetailsPane}/>
      :
      <DetailsPane setCurrentDetailsPane={setCurrentDetailsPane} projectId={currentDetailsPane}/>
      }

</>
    
  );
}

export default App;
