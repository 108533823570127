import { Text, Image, Box, Center, Flex, Heading, HStack, SimpleGrid, VStack } from "@chakra-ui/react"

const LeftColTile: React.FC<{
  imageFile: string,
  title: string
}> = ({
  imageFile,
  title
}) => {
    return (
      <Center bg={`linear-gradient(57deg, rgba(8,12,68,0.65), rgba(8,12,68,0.45)), url(/assets/${imageFile})`} bgSize="cover" bgPosition="center" minH="180px" mx="25px" mb="25px" borderRadius="5px" >
        <Heading fontSize="28px" fontWeight="medium">{title}</Heading>
      </Center>
    )
  }

const RightPaneTile: React.FC<{
  imageFile: string,
  title: string
}> = ({
  imageFile,
  title
}) => {
    return (
      <Center bg={`linear-gradient(57deg, rgba(8,12,68,0.45), rgba(8,12,68,0.45)), url(/assets/${imageFile})`} bgSize="cover" bgPosition="center" h="100%" borderRadius="5px">
        <Heading textColor="white" fontSize="36px" fontWeight="medium">{title}</Heading>
      </Center>
    )
  }

export const HomeScreen: React.FC<{
  setCurrentDetailsPane: (id: string) => void
}> = ({
  setCurrentDetailsPane
}) => {

    return (
      <Flex direction="row" minH="100vh">

        {/* Left col */}
        <Flex direction="column" bgColor="#080C44" textColor="white" width="400px">

          {/* Logo block */}
          <Center flex={1} onClick={() => document.body.requestFullscreen()}>
            <Image p="62.5px" src="/assets/cklogo.png" />
          </Center>

          {/* About us */}
          <Box onClick={() => setCurrentDetailsPane("aboutus")}>
            <LeftColTile imageFile="bg-aboutus.jpg" title="CannonKeysの紹介" />
          </Box>

          {/* About us */}
          <Box onClick={() => setCurrentDetailsPane("aboutkeyboards")}>
            <LeftColTile imageFile="bg-aboutkeyboards.jpg" title="カスタムキーボードとは" />
          </Box>


        </Flex>

        {/* Right col */}
        <Flex direction="column" flex={1}>
          <Center>
            <Text mt={8} fontSize="4xl">今回の展示品</Text>
          </Center>

          <SimpleGrid flex={1} columns={2} spacing="12.5px" m="35px">
            <Box onClick={() => setCurrentDetailsPane("brutal-v2")}>
              <RightPaneTile imageFile="cover-brutalv2.jpg" title="Brutal V2" />
            </Box>
            <Box onClick={() => setCurrentDetailsPane("altair-x")}>
              <RightPaneTile imageFile="cover-altairx.jpg" title="Altair-X" />
            </Box>
            <Box onClick={() => setCurrentDetailsPane("vector")}>
              <RightPaneTile imageFile="cover-vector.jpg" title="Vector" />
            </Box>
            <Box onClick={() => setCurrentDetailsPane("andromeda")}>
              <RightPaneTile imageFile="cover-andromeda.jpg" title="Andromeda" />
            </Box>
            <Box onClick={() => setCurrentDetailsPane("ripple")}>
              <RightPaneTile imageFile="cover-ripple.jpg" title="Ripple" />
            </Box>
            <Flex direction="column">
              <Center flex={1} bgImage="/assets/qrcode.png" bgSize="contain" bgRepeat="no-repeat" bgPosition="center" backgroundSize="100px"/>
              <Center>
                <small>cannonkeys.com/meetup-202303-jp</small>
              </Center>
              
            </Flex>
          </SimpleGrid>
        </Flex>


      </Flex>
    )
  }